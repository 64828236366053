import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Content from "../../../components/Content";
import InputMask from "react-input-mask";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory, useParams } from "react-router-dom";
import { defaultAlert } from "../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { CreateUserSchema } from "../../../helpers/validation/Schemas/UserSchema/schema";
import { states } from "../../../helpers/states";

import {
  validateUserCPF,
  createUser,
  getUserById,
  updateUser,
} from "../../../services/endpoints/users/endpoints";
import { getFormatedDateSystem } from "../../../helpers/dateHelpers";
import {
  getCouncilTypes,
  getAccessProfileTypes,
} from "../../../services/endpoints/enums/endpoints";

import { getHealthUnits } from "../../../services/endpoints/healthUnit/endpoints";

export default function CreateUsers() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [cpf, setCPF] = useState("");
  const [celular, setCelular] = useState("");
  const [tpCouncilOptions, setTpCouncilOptions] = useState([]);
  const [tpProfileOptions, setTpProfileOptions] = useState([]);
  const [tpHealthUnitOptions, setTpHealthUnitOptions] = useState([]);
  const [conselho, setConselho] = useState('');

  useEffect(() => {
    const populateTpProfileOptions = async () => {
      await getAccessProfileTypes().then(({ data }) => {
        setTpProfileOptions(data.list);
      });
    };
    populateTpProfileOptions();
  }, []);

  useEffect(() => {
    const populateTpCouncil = () => {
      getCouncilTypes().then(({ data }) => {
        setTpCouncilOptions(data.list);
      });
    };
    populateTpCouncil();
  }, []);

  useEffect(() => {
    const populateHealthUnits = () => {
      getHealthUnits().then(({ data }) => {
        setTpHealthUnitOptions(data.list);
      });
    };
    populateHealthUnits();
  }, []);

  const {
    register,
    errors,
    handleSubmit,
    control,
    setError,
    reset,
    setValue,
    watch,
    getValues,
    clearError,
    formState,
  } = useForm({
    validationSchema: CreateUserSchema,
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      nome: "",
      tp_conselho: "",
      conselho: "",
      nr_conselho: "",
      uf_conselho: "",
      cd_usuario: "",
      perfil_de_acesso: "",
      is_profissional_de_saude: false,
      id_unidade: "",
      email: "",
      data_nascimento: "",
      cpf: "",
      celular: "",
      tp_situacao: "A",
    },
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      setIsEditing(true);
      const handleFetchUser = async () => {
        await getUserById(id)
          .then(({ data }) => {
            const {
              nome,
              is_profissional_de_saude,
              id_unidade,
              cd_usuario,
              email,
              data_nascimento,
              perfil_de_acesso,
              cpf,
              celular,
              tp_situacao,
              tp_conselho,
              conselho,
              nr_conselho,
              uf_conselho,
            } = data.usuario;
            setValue([
              { nome },
              { perfil_de_acesso: perfil_de_acesso.toString() },
              { is_profissional_de_saude },
              { cpf },
              { id_unidade },
              { tp_conselho },
              { conselho }, 
              { nr_conselho },
              { uf_conselho },
              { cd_usuario },
              { email },
              { data_nascimento },
              { tp_situacao },
            ]);
            setConselho(data.usuario.conselho);
            setCelular(celular);
            setCPF(cpf);
          })
          .catch((error) => {
            history.push("/users/list");
            setIsEditing(false);
          });
        setIsLoading(false);
      };
      handleFetchUser();
    }
    return () => {};
  }, [id, history, setValue]);

  const isHealthProfessional = watch("is_profissional_de_saude");
  const watchPerfilDeAcesso = watch("perfil_de_acesso");
  const watchTpConselho = watch("tp_conselho");

  useEffect(() => {
    setValue('conselho', !!conselho ? conselho : '');
    // eslint-disable-next-line
  }, [watchTpConselho, conselho]);

  const { dirty } = formState;

  function checkAndRemoveAdminSysFromOptions() {
    if (isEditing && getValues("perfil_de_acesso") !== 0) {
      setTpProfileOptions(
        tpProfileOptions.filter((profile) => profile.value !== 0)
      );
    }
  }

  async function cpfIsValid(data) {
    const jsonCPF = {
      cpf: data.cpf,
    };

    return await validateUserCPF(jsonCPF)
      .then(({ data }) => {
        if (data.status) {
          return data.status;
        } else {
          defaultAlert(data.msg);
          setError("cpf", "notMatch", data.msg);
          return data.status;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  async function handleForm(data) {
    setIsLoading(true);
    const formData = data;

    formData.cpf = formData.cpf.replace(/\D+/g, "");
    formData.data_nascimento = getFormatedDateSystem(formData.data_nascimento);

    if (formData.nr_conselho) {
      formData.nr_conselho = formData.nr_conselho.toString();
    }

    if (!isEditing) {
      delete formData.tp_situacao;
    }

    if (watchPerfilDeAcesso === 0 || getValues("perfil_de_acesso") === "0") {
      delete formData.is_profissional_de_saude;
      delete formData.nr_conselho;
      delete formData.uf_conselho;
      delete formData.tp_conselho;
      delete formData.conselho;
      delete formData.id_unidade;
    }

    if (!isHealthProfessional) {
      delete formData.nr_conselho;
      delete formData.uf_conselho;
      delete formData.tp_conselho;
      delete formData.conselho;
    }

    const isValid = await cpfIsValid(formData);

    if (isValid) {
      if (isEditing) {
        handleUpdateUser(id, formData);
      } else {
        handleCreateUser(formData);
      }
    } else {
      setIsLoading(false);
    }
  }

  function handleCreateUser(data) {
    createUser(data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Usuário criado com Sucesso!",
            false,
            "Ver lista de usuários",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/users/list");
            }
          });
          setIsLoading(false);
          reset();
          setCelular("");
          setCPF("");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
          setIsLoading(false);
          return false;
        }
      });
  }

  function handleUpdateUser(id, data) {
    updateUser(id, data)
      .then(({ data }) => {
        if (data.status) {
          defaultAlert(
            "Usuário atualizado com Sucesso!",
            false,
            "Ver lista de usuários",
            "success",
            "Tudo certo!"
          ).then((result) => {
            if (result.value) {
              history.push("/users/list");
            }
          });
          setIsLoading(false);
          reset();
          setCelular("");
          setCPF("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;

          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });

          setIsLoading(false);
          return false;
        }
        setIsLoading(false);
      });
  }

  function handleChangeCPF(event) {
    setValue("cpf", event.target.value);
    setCPF(event.target.value);
  }

  function handleChangeCelular(event) {
    setValue("celular", event.target.value);
    setCelular(event.target.value);
  }

  return (
    <>
      <Content formIsDirty={dirty}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container className={classes.gridRoot}>
          <Box className={classes.paperStyle} component={Paper} elevation={2}>
            <Typography component="h1" variant="h6">
              {isEditing ? "Editar Usuário" : "Novo Usuário"}
            </Typography>
            <form
              method="POST"
              onSubmit={handleSubmit((data) => handleForm(data))}
            >
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.nome}
                  className={classes.flexItems}
                >
                  <InputLabel>Nome Completo</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="nome"
                  />
                  <FormHelperText>{errors?.nome?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.cd_usuario}
                  className={classes.flexItems}
                >
                  <InputLabel>Login</InputLabel>
                  <Controller
                    onChange={([event]) =>
                      event.target.value.trim().toLowerCase()
                    }
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="cd_usuario"
                  />
                  <FormHelperText>{errors?.cd_usuario?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.senha}
                  className={classes.flexItems}
                >
                  <InputLabel>Senha</InputLabel>
                  <Input
                    inputRef={register({ required: true })}
                    name="senha"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{errors?.senha?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.confirmacao_senha}
                  className={classes.flexItems}
                >
                  <InputLabel>Confirmação de Senha</InputLabel>
                  <Input
                    inputRef={register({ required: true })}
                    name="confirmacao_senha"
                    autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>{errors?.confirmacao_senha?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.data_nascimento}
                  className={classes.flexItems}
                >
                  <InputLabel shrink={true}>Data de Nascimento</InputLabel>
                  <Input
                    name="data_nascimento"
                    type="date"
                    inputProps={{min:"01/01/2020" ,max: new Date().toISOString().split("T")[0]}}
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText>
                    {errors?.data_nascimento?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl error={!!errors.cpf} className={classes.flexItems}>
                  <InputLabel>CPF</InputLabel>
                  <InputMask
                    mask="999.999.999-99"
                    disableUnderline
                    maskChar={" "}
                    value={cpf}
                    onChange={handleChangeCPF}
                    onFocus={() => clearError("cpf")}
                  >
                    {() => <Input name="cpf" inputRef={register()} />}
                  </InputMask>
                  <FormHelperText>{errors?.cpf?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.celular}
                  className={classes.flexItems}
                >
                  <InputLabel>Celular</InputLabel>
                  <InputMask
                    mask="(99)99999-9999"
                    disableUnderline
                    maskChar={null}
                    value={celular}
                    onChange={handleChangeCelular}
                    onFocus={() => clearError("celular")}
                  >
                    {() => (
                      <Input
                        name="celular"
                        inputRef={register({ required: true })}
                      />
                    )}
                  </InputMask>
                  <FormHelperText>{errors?.celular?.message}</FormHelperText>
                </FormControl>
              </Box>
              <Box className={classes.flexContainer}>
                <FormControl
                  error={!!errors.email}
                  className={classes.flexItems}
                >
                  <InputLabel>Email</InputLabel>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    control={control}
                    name="email"
                  />
                  <FormHelperText>{errors?.email?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  disabled={isEditing && getValues("perfil_de_acesso") === "0"}
                  error={!!errors.perfil_de_acesso}
                  className={classes.flexItems}
                >
                  <InputLabel>Perfil de Acesso</InputLabel>
                  <Controller
                    as={
                      <Select onOpen={checkAndRemoveAdminSysFromOptions}>
                        <MenuItem value="">Selecione</MenuItem>
                        {tpProfileOptions.map((profile, index) => (
                          <MenuItem
                            key={profile.value + index}
                            value={profile.value}
                          >
                            {profile.description}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    rules
                    control={control}
                    name="perfil_de_acesso"
                  />
                  <FormHelperText>
                    {errors?.perfil_de_acesso?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  style={{
                    display: watchPerfilDeAcesso > 0 ? "" : "none",
                  }}
                  error={!!errors.id_unidade}
                  className={classes.flexItems}
                >
                  <InputLabel>Unidade de Saúde</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione</MenuItem>
                        {tpHealthUnitOptions.map((unit, index) => (
                          <MenuItem
                            key={unit.id_unidade + index}
                            value={unit.id_unidade}
                          >
                            {unit.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    rules
                    control={control}
                    name="id_unidade"
                  />
                  <FormHelperText>{errors?.id_unidade?.message}</FormHelperText>
                </FormControl>
                <FormControl
                  style={{
                    display: watchPerfilDeAcesso > 0 ? "" : "none",
                  }}
                  error={!!errors.is_profissional_de_saude}
                  className={classes.flexItems}
                >
                  <FormLabel component="legend">
                    É profissional de saúde?
                  </FormLabel>
                  <FormGroup aria-label="position" row>
                    <Controller
                      name="is_profissional_de_saude"
                      as={<Checkbox color="primary" />}
                      control={control}
                      type="checkbox"
                    />
                  </FormGroup>
                  <FormHelperText>
                    {errors?.is_profissional_de_saude?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box
                style={{
                  display:
                    isHealthProfessional && watchPerfilDeAcesso > 0
                      ? ""
                      : "none",
                }}
                className={classes.flexContainer}
              >
                <FormControl
                  error={!!errors.tp_conselho}
                  className={classes.flexItems}
                >
                  <InputLabel>Tipo de Conselho</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione</MenuItem>
                        {tpCouncilOptions.map((option, index) => (
                          <MenuItem
                            key={option.value + index}
                            value={option.value}
                          >
                            {option.description}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    control={control}
                    name="tp_conselho"
                  />
                  <FormHelperText>
                    {errors?.tp_conselho?.message}
                  </FormHelperText>
                </FormControl>
                {watchTpConselho === 'Outros' ?
                  (<FormControl
                    error={!!errors.conselho}
                    className={classes.flexItems}
                  >
                    <InputLabel>Conselho</InputLabel>
                    <Controller
                      as={<Input />}
                      control={control}
                      name="conselho"
                    />
                    <FormHelperText>
                      {errors?.conselho?.message}
                    </FormHelperText>
                  </FormControl>) : null
                }
                <FormControl
                  error={!!errors.nr_conselho}
                  className={classes.flexItems}
                >
                  <InputLabel>Numero do Conselho</InputLabel>
                  <Controller
                    as={<Input />}
                    control={control}
                    name="nr_conselho"
                  />
                  <FormHelperText>
                    {errors?.nr_conselho?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  error={!!errors.uf_conselho}
                  className={classes.flexItems}
                >
                  <InputLabel>UF do Conselho</InputLabel>
                  <Controller
                    as={
                      <Select>
                        <MenuItem value="">Selecione</MenuItem>
                        {states.map((state, index) => (
                          <MenuItem key={state + index} value={state.sigla}>
                            {state.sigla}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    rules
                    control={control}
                    name="uf_conselho"
                  />
                  <FormHelperText>
                    {errors?.uf_conselho?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              {isEditing ? (
                <Box className={classes.flexContainer}>
                  <FormControl
                    error={!!errors.tp_situacao}
                    className={classes.flexItems}
                  >
                    <InputLabel>Situação</InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value={"A"}>Ativo</MenuItem>
                        </Select>
                      }
                      rules
                      control={control}
                      name="tp_situacao"
                    />
                    <FormHelperText>
                      {errors?.tp_situacao?.message}
                    </FormHelperText>
                  </FormControl>
                </Box>
              ) : (
                  ""
                )}
              <Box className={classes.flexContainerButton}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {isEditing ? "Atualizar Usuário" : "Cadastrar Usuário"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "flex-start",
  },
  flexItems: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
    width: "100%",
  },
}));
